/* Core stylings*/

body {
  margin: 0;
  font-family: "helvetica sans-serif"; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100% !important;
  overflow-x: hidden !important;
  height: 100vh;
}

#root {
    position: relative;
    overflow-x: hidden;
    display: flex;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
    -webkit-tap-highlight-color:transparent;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar-track {
	border-radius: 10px;
    width:15px;
}

*::-webkit-scrollbar
{
	width: 15px;
	border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
}

*::-webkit-scrollbar-thumb
{
	border-radius: 10px;
    width: 16px;
	background-color: rgba(0, 0, 0, 0.123);
}
*::-webkit-scrollbar-thumb:hover
{
	border-radius: 10px;
    width: 16px;
	background-color: rgba(0, 0, 0, 0.253);
}